import React from "react";
import styles from "./style.module.css";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { getUserPool } from "../../config";
import { SubmitHandler, useForm } from "react-hook-form";
import loginImage from "../../images/loginImage.jpg";
import { GoogleSignInButton } from "../../services/GoogleSignInButton";

interface LoginForm {
  email: string;
  password: string;
}

interface Props {
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}

export const Login = (props: Props) => {
  const { register, handleSubmit } = useForm<LoginForm>();

  const Login: SubmitHandler<LoginForm> = (data) => {
    const userPool = getUserPool("COGNITO");
    const userData = {
      Username: data.email,
      Pool: userPool,
    };
    const authenticationData = {
      Username: data.email,
      Password: data.password,
    };
    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        props.setStatus("loggedIn");
      },

      onFailure: (err) => {
        alert(err.message || JSON.stringify(err));
        console.log(err);
      },
    });
  };

  return (
    <div className={styles.loginPageWrapper}>
      <img src={loginImage} alt="loginImage" className={styles.image} />
      <div className={styles.loginForm}>
        <div>
          <p className={styles.loginMessage}>Hello,</p>
          <p className={styles.loginMessage}>Welcome back!</p>
        </div>
        <div className={styles.inputWrapper}>
          <input
            type="email"
            className={styles.loginInput}
            placeholder="Email"
            required
            {...register("email")}
          />
          <input
            type="password"
            className={styles.loginInput}
            placeholder="Password"
            required
            {...register("password")}
          />
        </div>
        <button className={styles.button} onClick={handleSubmit(Login)}>
          Login
        </button>
        <GoogleSignInButton setStatus={props.setStatus} />
        <div className={styles.signUpPageButton}>
          <p>Don't have an account?</p>
          <a
            href="/signup"
            className={styles.singUpPage}
            onClick={() => {
              props.setStatus("not_loggedIn");
            }}
          >
            Click here
          </a>
        </div>
      </div>
    </div>
  );
};
