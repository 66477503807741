import APIcore, { apiRequest } from "./core/APIcore";
import { fetchMyTaskListRequest } from "./fetchMyTaskListRequest";

type finishTaskRequestProps = {
  taskId: number;
  setTaskList: any;
};

export const finishTaskRequest = async (props: finishTaskRequestProps) => {
  const taskId = props.taskId;

  const apiFinish: apiRequest = {
    url: `users/Id/tasks/${taskId}`,
  };
  const response = await APIcore.post(apiFinish);

  if (response.status === 200) {
    const fetchTaskListFromAPIProps = {
      setTaskList: props.setTaskList,
    };
    fetchMyTaskListRequest(fetchTaskListFromAPIProps);
  }
};
