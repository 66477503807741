import { AccountType, getUserPool } from "../../config";

export type apiRequest = {
  url?: string;
  requestItems?: any;
  authorization?: string;
  accountType?: AccountType;
};

class APIcore {
  //Post method
  static async post(props: apiRequest) {
    const postAPIRequestByAccountType = async (user: any, url?: string) => {
      const getIdToken = async () => {
        return new Promise((resolve, reject) => {
          try {
            user.getCurrentUser().getSession((error: any, session: any) => {
              if (error) {
                console.error("getSession error", error);
                reject(error);
              } else {
                resolve(session.idToken.jwtToken);
              }
            });
          } catch (error) {
            console.error("Error getting ID token", error);
            reject(error);
          }
        });
      };
      let idToken = "";
      try {
        idToken = (await getIdToken()) as string;
      } catch (error) {
        console.log("Error getting ID token", error);
      }

      const response = await fetch(
        `https://${process.env.REACT_APP_API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/v1/${props.url}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            authorization: idToken as string,
          },
          body: JSON.stringify(props.requestItems),
        }
      );
      return response;
    };

    const userPool = getUserPool("COGNITO");
    const currentUser = userPool.getCurrentUser();
    if (currentUser !== null) {
      const response = await postAPIRequestByAccountType(userPool, props.url);
      return response;
    } else {
      const userPool = getUserPool("GOOGLE");
      const response = await postAPIRequestByAccountType(userPool, props.url);
      return response;
    }
  }

  // Get method
  static get(props: apiRequest) {
    const getAPIRequestByAccountType = async (user: any, url?: string) => {
      // Wrap getSession in a promise to handle asynchronous nature
      const getIdToken = () => {
        return new Promise((resolve, reject) => {
          user.getCurrentUser()?.getSession((error: any, session: any) => {
            if (error) {
              reject(error);
            } else {
              resolve(session.idToken.jwtToken);
            }
          });
        });
      };

      try {
        const idToken = await getIdToken();
        const response = await fetch(
          `https://${process.env.REACT_APP_API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/v1/${props.url}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              authorization: idToken as string,
            },
            body: JSON.stringify(props.requestItems),
          }
        );
        return response;
      } catch (error) {
        console.error("Error getting ID token", error);
        throw error;
      }
    };

    const userPool = getUserPool("COGNITO");
    const currentUser = userPool.getCurrentUser();

    if (currentUser !== null) {
      return getAPIRequestByAccountType(userPool, props.url);
    } else {
      const userPool = getUserPool("GOOGLE");
      return getAPIRequestByAccountType(userPool, props.url);
    }
  }

  //Put method
  static put(props: apiRequest) {
    const putAPIRequestByAccountType = async (user: any, url?: string) => {
      // Wrap getSession in a promise to handle asynchronous nature
      const getIdToken = () => {
        return new Promise((resolve, reject) => {
          user.getCurrentUser()?.getSession((error: any, session: any) => {
            if (error) {
              reject(error);
            } else {
              resolve(session.idToken.jwtToken);
            }
          });
        });
      };

      try {
        const idToken = await getIdToken();
        const response = await fetch(
          `https://${process.env.REACT_APP_API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/v1/${props.url}`,
          {
            method: "PUT",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              authorization: idToken as string,
            },
            body: JSON.stringify(props.requestItems),
          }
        );
        return response;
      } catch (error) {
        console.error("Error getting ID token", error);
        throw error;
      }
    };

    const userPool = getUserPool("COGNITO");
    const currentUser = userPool.getCurrentUser();

    if (currentUser !== null) {
      return putAPIRequestByAccountType(userPool, props.url);
    } else {
      const userPool = getUserPool("GOOGLE");
      return putAPIRequestByAccountType(userPool, props.url);
    }
  }

  static delete(props: apiRequest) {
    const response = fetch(
      `https://${process.env.REACT_APP_API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/v1/${props.url}`,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  }

  static patch(props: apiRequest) {
    const patchAPIRequestByAccountType = async (user: any, url?: string) => {
      // Wrap getSession in a promise to handle asynchronous nature
      const getIdToken = () => {
        return new Promise((resolve, reject) => {
          user.getCurrentUser()?.getSession((error: any, session: any) => {
            if (error) {
              reject(error);
            } else {
              resolve(session.idToken.jwtToken);
            }
          });
        });
      };

      try {
        const idToken = await getIdToken();
        const response = await fetch(
          `https://${process.env.REACT_APP_API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/v1/${props.url}`,
          {
            method: "PATCH",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              authorization: idToken as string,
            },
            body: JSON.stringify(props.requestItems),
          }
        );
        return response;
      } catch (error) {
        console.error("Error getting ID token", error);
        throw error;
      }
    };

    const userPool = getUserPool("COGNITO");
    const currentUser = userPool.getCurrentUser();

    if (currentUser !== null) {
      return patchAPIRequestByAccountType(userPool, props.url);
    } else {
      const userPool = getUserPool("GOOGLE");
      return patchAPIRequestByAccountType(userPool, props.url);
    }
  }
}

export default APIcore;
