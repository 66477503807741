import { TaskListType } from "../components/organisms/Task/Task";
import APIcore, { apiRequest } from "./core/APIcore";

type fetchTaskListFromAPIProps = {
  setTaskList: React.Dispatch<React.SetStateAction<TaskListType[]>>;
};

export const fetchMyTaskListRequest = (props: fetchTaskListFromAPIProps) => {
  const fetchTaskList = async () => {
    const apiGet: apiRequest = {
      url: `users/id/tasks`,
    };
    const response = await APIcore.get(apiGet);
    const data = await response.json();
    props.setTaskList(data);
  };
  fetchTaskList();
};
