import React from "react";
import "./App.css";
import Routers from "./routers/AppRouter";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

  return (
    <div className="App">
      <GoogleOAuthProvider clientId={clientId}>
        <Routers />
      </GoogleOAuthProvider>
    </div>
  );
}
export default App;
