/*
タスク内容の送信
lambda側で雛形作って、タスク内容等を入れる

TODO  必要なもの
タスク作成者の情報
タスクのタイトル
タスクの日時
*/

import APIcore, { apiRequest } from "./core/APIcore";

type sesNotificationProps = {
  // TODO organize type for task
  taskTitle: string;
  taskUserName: string;
  taskDate: string;
  startTime: number;
  endTime: number;
  supporterCognitoId: string;
};
const convertToCurrentTime = (time: any) => {
  if (!time) return;
  const timeStamp = Number(time);
  const localTimeStamp = new Date(timeStamp);
  const hours = localTimeStamp.getHours();
  const minutes = localTimeStamp.getMinutes();

  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  return formattedTime;
};

export const sesNotificationRequest = async (props: sesNotificationProps) => {
  const apiPost: apiRequest = {
    url: "sesEmailNotification",
    requestItems: {
      title: props.taskTitle,
      taskUserName: props.taskUserName,
      date: props.taskDate,
      startTime: convertToCurrentTime(props.startTime),
      endTime: convertToCurrentTime(props.endTime),
      cognito_id: props.supporterCognitoId,
    },
  };

  try {
    const response = await APIcore.put(apiPost);
    if (response.status === 200) {
      console.log("Send a message to your supporter");
    }
  } catch (error) {
    console.error("Error sending email notification:", error);
  }
};
