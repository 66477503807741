import APIcore, { apiRequest } from "./core/APIcore";
import { fetchMessagesRequest } from "./fetchMessagesRequest";

export type messageType = string | null;

export type messageListType = {
  created_at: "";
  deleted_at: "" | null;
  id: number;
  message: string;
  receiver_id: number;
  sender_id: number;
  task_id: number;
  updated_id: "" | null;
};

export type messageDataRequestType = {
  message: string | null;
  senderId: number | null;
  receiverId?: number | null;
  taskId: number | null;
  setMessagesList: React.Dispatch<React.SetStateAction<messageListType[]>>;
} | null;

export const sendMessageRequest = async (props: messageDataRequestType) => {
  const apiPost: apiRequest = {
    url: "messages",
    requestItems: {
      senderId: props!.senderId,
      receiverId: props!.receiverId,
      taskId: props!.taskId,
      message: props!.message,
    },
  };

  const response = await APIcore.post(apiPost);
  if (response.status === 200) {
    const fetchMessagesFromAPIProps = {
      setMessagesList: props!.setMessagesList,
    };
    fetchMessagesRequest(fetchMessagesFromAPIProps);
  }
};
