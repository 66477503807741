import { useState } from "react";
import styles from "./style.module.css";
import Swal from "sweetalert2";
import {
  messageDataRequestType,
  messageListType,
  messageType,
  sendMessageRequest,
} from "../../../../services/sendMessageRequest";
import { userInfoType } from "../../../../routers";
import { TextField } from "@mui/material";

type MessageContainerProps = {
  userInfo: userInfoType;
  supporterName: string;
  senderId: number;
  receiverId: number;
  taskId: number;
  messagesList: any;
  setMessagesList: React.Dispatch<React.SetStateAction<messageListType[]>>;
};

export const MessageContainer = (props: MessageContainerProps) => {
  const [message, setMessage] = useState<messageType>(null);
  const onClickStop = (e: any) => {
    e.stopPropagation();
  };

  const handleMessage = (event: string) => {
    setMessage(event);
  };

  const sendMessage = () => {
    if (!message) {
      Swal.fire({
        icon: "error",
        title: "Please enter a message",
      });
      return;
    }
    Swal.fire({
      icon: "question",
      title: "Is this okay?",
      html: `Message：${message}`,
      showCancelButton: true,
    }).then((result) => {
      const sendMessagesProps: messageDataRequestType = {
        message: message!,
        senderId: props.userInfo.userId!,
        receiverId: props.receiverId,
        taskId: props.taskId,
        setMessagesList: props.setMessagesList!,
      };
      result.value && sendMessageRequest(sendMessagesProps);
      setMessage("");
    });
  };

  // messageListの中で一番新しいものを見せる。
  const filteredByTaskID =
    props.messagesList &&
    props.messagesList.filter(
      (message: any) => message.task_id === props.taskId
    );

  // フィルタリングされたデータから最新のものを取得
  const latestMessage =
    filteredByTaskID &&
    filteredByTaskID.reduce((prev: any, current: any) => {
      if (!prev) {
        return current;
      }
      return prev.created_at > current.created_at ? prev : current;
    }, filteredByTaskID[0]);

  return (
    <>
      <div className={styles.messageContainerWrapper}>
        {filteredByTaskID &&
          latestMessage &&
          (latestMessage.sender_id === props.userInfo.userId! ? (
            <div className={styles.messageContainerTopUser}>
              <div className={styles.lastMessage}>{latestMessage.message}</div>
              <div className={styles.userName}>：You 😄</div>
            </div>
          ) : (
            <div className={styles.messageContainerTopSupporter}>
              <div className={styles.supporterName}>
                {props.supporterName} 😄：
              </div>
              <div className={styles.lastMessage}>{latestMessage.message}</div>
            </div>
          ))}

        <div className={styles.messageContainerBottom} onClick={onClickStop}>
          {/* <input
            type="text"
            className={styles.messageInput}
            value={message || ""}
            onChange={(e) => handleMessage(e.target.value)}
          /> */}
          <TextField
            id="outlined-basic"
            label={"message here"}
            variant="outlined"
            value={message || ""}
            onChange={(e) => handleMessage(e.target.value)}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "20px",
                boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
                "& fieldset": {
                  borderRadius: "20px",
                  border: "none",
                },
              },
            }}
          />
          <button className={styles.sendButton} onClick={sendMessage}>
            Send
          </button>
        </div>
      </div>
    </>
  );
};
