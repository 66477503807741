export const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const todayFormulated = `${year}/${month}/${day}`;
  return todayFormulated;
};

export const returnSelectedDate = (event: any) => {
  const date = new Date(event);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const newDate = `${year}/${month}/${day}`;
  return newDate;
};
