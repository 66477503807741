import APIcore, { apiRequest } from "./core/APIcore";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { googleSignUp } from "./googleSignUp";

type googleLoginProps = {
  code: string;
  userName: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
};
export const googleLogin = async (props: googleLoginProps) => {
  // TODO ユーザーがいるかの分岐
  // TODO プロフィールの名前とってきて入れる

  const googleLoginRequest = async () => {
    const apiPost: apiRequest = {
      url: `login`,
      requestItems: { googleCode: props.code },
    };
    const response = await APIcore.post(apiPost);
    const data = await response.json();
    console.log("data", data);
    let userName: string | undefined;
    let googleUserSub: string = "";
    try {
      const response = await fetch(
        `https://terraform-mythril-${process.env.REACT_APP_COGNITO_ENV}.auth.ap-northeast-1.amazoncognito.com/oauth2/userInfo`,
        {
          headers: {
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      const googleUseData = await response.json();
      userName = googleUseData.name as string;
      googleUserSub = googleUseData.sub as string;
      console.log("googleUseData", googleUseData);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }

    if (data.id_token && userName) {
      const pool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
        ClientId: process.env.REACT_APP_COGNITO_GOOGLE_USER_POOL_CLIENT_ID!,
      });
      var cognitoUser = new CognitoUser({
        Pool: pool,
        Username: userName,
      });
      pool.getCurrentUser();
      const sessionData: AmazonCognitoIdentity.ICognitoUserSessionData = {
        IdToken: new AmazonCognitoIdentity.CognitoIdToken({
          IdToken: data.id_token,
        }),
        AccessToken: new AmazonCognitoIdentity.CognitoAccessToken({
          AccessToken: data.access_token,
        }),
        RefreshToken: new AmazonCognitoIdentity.CognitoRefreshToken({
          RefreshToken: data.refresh_token,
        }),
      };
      const session: AmazonCognitoIdentity.CognitoUserSession =
        new AmazonCognitoIdentity.CognitoUserSession(sessionData);
      cognitoUser.setSignInUserSession(session);

      const response = await fetch(
        `https://${process.env.REACT_APP_API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/v1/users`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            authorization: data.id_token,
          },
        }
      );

      const userInfo = await response.json();
      console.log("userInfo", userInfo);
      if (userInfo.length < 1) {
        const googleSignUpProps = {
          userName: userName,
          cognito_id: googleUserSub,
        };
        googleSignUp(googleSignUpProps);
        console.log("googleSignup!!");
      }
    }
  };

  await googleLoginRequest();
};
