import { userInfoType } from "../routers";
import APIcore, { apiRequest } from "./core/APIcore";
import { fetchUserNameRequest } from "./fetchUserName";

type editTaskRequestProps = {
  name?: string;
  age?: number;
  gender?: string;
  nationality?: string;
  address?: string;
  birthday?: string;
  setUserInfo: React.Dispatch<React.SetStateAction<userInfoType>>;
};
export const updateUserInfoRequest = async (props: editTaskRequestProps) => {
  const apiPatch: apiRequest = {
    url: `users/id/`,
    requestItems: {
      name: props.name,
      age: props.age,
      gender: props.gender,
      nationality: props.nationality,
      address: props.address,
      birthday: props.birthday,
    },
  };

  const response = await APIcore.patch(apiPatch);

  // TODO ここでリストを取るフェッチを走らせる（リロード関数とか作ると汎用性あるかもね）
  if (response.status === 200) {
    const fetchUserData = async () => {
      const userData = await fetchUserNameRequest();
      props.setUserInfo((prev) => ({
        ...prev,
        userId: userData.id,
        userName: userData.name,
      }));
    };
    fetchUserData();
  }
  return response;
};
