import { useNavigate } from "react-router-dom";
import { getUserPool } from "../../../config";
import styles from "./style.module.css";
import { useState } from "react";
import { FormControl, TextField } from "@mui/material";
import { updateUserInfoRequest } from "../../../services/updateUserInfoRequest";
import { userInfoType } from "../../../routers";

type SettingsProps = {
  userName: string | null;
  setUserInfo: React.Dispatch<React.SetStateAction<userInfoType>>;
};
export const Settings = (props: SettingsProps) => {
  const [newUserName, setNewUserName] = useState("");

  const handleNewUserName = (event: any) => {
    setNewUserName(event);
  };

  const handleSendNewUserName = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (window.confirm(`Is this name correct? → ${newUserName}`)) {
      console.log(newUserName);
      const updateUserInfoRequestProps = {
        name: newUserName,
        setUserInfo: props.setUserInfo,
      };
      const response = updateUserInfoRequest(updateUserInfoRequestProps);
      console.log(response);
    }
  };
  return (
    <>
      <div className={styles.settingsWrapper}>
        <div className={styles.cardWrapper}>
          <div className={styles.settingsCard}>
            <div className={styles.accountNameWrapper}>
              <p>Account name：{props.userName ? props.userName : "none"}</p>
              <form className={styles.form} onSubmit={handleSendNewUserName}>
                <FormControl
                  sx={{
                    width: "100%",
                  }}
                >
                  <div className={styles.inputWrapper}>
                    <div>
                      <TextField
                        required
                        id="outlined-basic"
                        label={"New account name"}
                        variant="outlined"
                        onChange={(e) => handleNewUserName(e.target.value)}
                        sx={{
                          width: "100%",
                          borderRadius: "10px",
                          "& fieldset": { border: "none" },
                          boxShadow: "0px 0px 15px rgb(17 21 26 / 15%)",
                          fontFamily: "inherit",
                        }}
                      />
                    </div>
                    <div className={styles.sendButtonWrapper}>
                      <button className={styles.sendButton}>Send</button>
                    </div>
                  </div>
                </FormControl>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
