import APIcore, { apiRequest } from "./core/APIcore";

type createUserProps = {
  userName?: string;
  cognito_id?: string;
};
export const createUserRequest = async (props: createUserProps) => {
  const apiPost: apiRequest = {
    url: "users",
    requestItems: {
      name: props.userName,
      created_at: new Date(),
      delete_flag: true,
      cognito_id: props.cognito_id,
    },
  };
  const response = await fetch(
    `https://${process.env.REACT_APP_API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/v1/users`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiPost.requestItems),
    }
  );
  const data = await response.json();
  console.log("data", data);
  return data;
};
