import { TaskListType } from "../components/organisms/Task/Task";
import APIcore, { apiRequest } from "./core/APIcore";

export type taskCreateProps = {
  taskTitle: string;
  taskDescription: string;
  scheduledTaskDate: string;
  message: string;
  startTime: number;
  endTime: number;
};

export type CreatedTaskData = {
  message: string;
  task: TaskListType;
  supporterCognitoId: string;
};

export const taskCreateRequest = async (
  props: taskCreateProps
): Promise<CreatedTaskData | undefined> => {
  const createdDate = Date.now();
  const startTimestamp = Number(props.startTime);
  const endTimestamp = Number(props.endTime);
  const timeDifference = endTimestamp - startTimestamp;
  const differenceInHours = Math.floor(timeDifference / (1000 * 60 * 60));
  const differenceInMinutes = Math.floor(
    (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
  );

  const apiPost: apiRequest = {
    url: "users/Id/tasks",
    requestItems: {
      title: props.taskTitle,
      description: props.taskDescription,
      date: props.scheduledTaskDate,
      hour: differenceInHours,
      minute: differenceInMinutes,
      created_at: createdDate,
      startTime: props.startTime,
      endTime: props.endTime,
    },
  };

  const response = await APIcore.post(apiPost);

  if (response.status === 200) {
    const data: CreatedTaskData = await response.json();
    return data;
  } else {
    return undefined;
  }
};
