import { useEffect, useState } from "react";
import styles from "./style.module.css";
import { FormControl, TextField } from "@mui/material";
import { TextareaAutosize } from "@mui/material";
import { styled } from "@mui/system";
import { DatePickerMUI } from "../../../library/DatePicker/DatePickerMUI";
import { getTodayDate, returnSelectedDate } from "../../../../utils/Date/date";
import Swal from "sweetalert2";
import {
  taskCreateProps,
  taskCreateRequest,
} from "../../../../services/createTaskRequest";
import { fetchMyTaskListRequest } from "../../../../services/fetchMyTaskListRequest";
import { TaskListType } from "../../../organisms/Task/Task";
import {
  messageDataRequestType,
  messageListType,
  sendMessageRequest,
} from "../../../../services/sendMessageRequest";
import { userInfoType } from "../../../../routers";
import ClickAwayListener from "react-click-away-listener";
import { sesNotificationRequest } from "../../../../services/sesNotificationRequest";
import { AnimatePresence, motion } from "framer-motion";

// MUIのカスタムCSSを適応させてる
const CustomTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  padding: "2rem",
  borderRadius: "30px",
  boxShadow: "0px 0px 15px rgb(17 21 26 / 15%)",
  resize: "none",
  border: "none",
  outline: "none",
  backgroundColor: "#ffffff",
  fontSize: "24px",
  fontFamily: "inherit",
}));

type TaskModalProps = {
  userInfo: userInfoType;
  taskId: number | null;
  taskList: TaskListType[];
  setTaskList: React.Dispatch<React.SetStateAction<TaskListType[]>>;
  setIsTaskAddModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMessagesList: React.Dispatch<React.SetStateAction<messageListType[]>>;
};
export const AddTaskModal = (props: TaskModalProps) => {
  const today = getTodayDate();
  const [scheduledTaskDate, setScheduledTaskDate] = useState(today);
  const getToday1930 = (scheduledTaskDate: any) => {
    const date = new Date(scheduledTaskDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const today1930 = new Date(year, month, day, 19, 30, 0, 0);
    return today1930.getTime();
  };

  const getToday2030 = (scheduledTaskDate: string) => {
    const date = new Date(scheduledTaskDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const today2030 = new Date(year, month, day, 20, 30, 0, 0);

    return today2030.getTime();
  };
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [firstMessageToSupporter, setFirstMessageToSupporter] = useState("");
  const [startTime, setStartTime] = useState<number>(
    getToday1930(scheduledTaskDate)
  );
  const [endTime, setEndTime] = useState<number>(
    getToday2030(scheduledTaskDate)
  );

  const handleTaskTitle = (event: string) => {
    setTaskTitle(event);
  };

  const handleTaskDescription = (event: string) => {
    setTaskDescription(event);
  };
  const handleFirstMessageToSupporter = (event: string) => {
    setFirstMessageToSupporter(event);
  };

  const setTime = (event: any, startOrEnd: string) => {
    const convertTimeStringToTimestamp = (
      timeString: string,
      dateString: string
    ) => {
      const [hours, minutes] = timeString.split(":").map(Number);
      const date = new Date(dateString);
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date.getTime();
    };

    const timestamp = convertTimeStringToTimestamp(event, scheduledTaskDate);
    if (startOrEnd === "start") {
      setStartTime(timestamp);
    } else {
      setEndTime(timestamp);
    }
  };
  useEffect(() => {
    setStartTime(getToday1930(scheduledTaskDate));
    setEndTime(getToday2030(scheduledTaskDate));
  }, [scheduledTaskDate]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (event.target[0].value.length > 40) {
      Swal.fire({
        icon: "error",
        title: "Please enter a task within 40 characters",
      });
      return;
    }
    Swal.fire({
      icon: "question",
      title: "Is this okay?",
      html: `Task Title：${taskTitle} <br><br> Description ：${taskDescription}
      `,
      showCancelButton: true,
    }).then(async (result) => {
      const taskCreateProps: taskCreateProps = {
        taskTitle: taskTitle,
        taskDescription: taskDescription,
        scheduledTaskDate,
        message: firstMessageToSupporter,
        startTime: startTime,
        endTime: endTime,
      };
      if (!result.value) {
        return;
      }
      const createdTaskData = await taskCreateRequest(taskCreateProps);
      // sending the first message
      if (!createdTaskData) {
        console.log("task create failed");
        return;
      }
      const supporterCognitoId = createdTaskData.supporterCognitoId;
      const sendMessagesProps: messageDataRequestType = {
        message: firstMessageToSupporter,
        senderId: createdTaskData.task.user_id,
        receiverId: createdTaskData.task.supporter_id,
        taskId: createdTaskData.task.id,
        setMessagesList: props.setMessagesList!,
      };
      sendMessageRequest(sendMessagesProps);
      const fetchTaskListFromAPIProps = {
        setTaskList: props.setTaskList,
      };
      fetchMyTaskListRequest(fetchTaskListFromAPIProps);
      const sesNotificationProps = {
        taskUserName: props.userInfo.userName!,
        taskTitle: createdTaskData.task.title,
        taskDate: createdTaskData.task.date,
        startTime: createdTaskData.task.start_time,
        endTime: createdTaskData.task.end_time,
        supporterCognitoId: supporterCognitoId,
      };

      // nutコストを考慮して、sesのAPIの処理がtaskCreateの中に書いていない。githubのissue No ~
      sesNotificationRequest(sesNotificationProps);
    });
    props.setIsTaskAddModalOpen(false);
  };

  const handleCloseModal = () => {
    props.setIsTaskAddModalOpen(false);
  };

  return (
    <AnimatePresence>
      <form className={styles.form} onSubmit={handleSubmit}>
        <FormControl>
          <motion.div
            className={styles.modalWrapper}
            key="modal"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
          >
            <ClickAwayListener onClickAway={handleCloseModal}>
              <div className={styles.modal}>
                <div className={styles.modelGap}>
                  <div className={styles.modalTop}>
                    <div className={styles.modalTopRight}>
                      <TextField
                        required
                        id="outlined-basic"
                        label={"Task Title"}
                        variant="outlined"
                        onChange={(e) => handleTaskTitle(e.target.value)}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            padding: "1rem",
                            fontSize: "18px",
                            borderRadius: "30px",
                            boxShadow:
                              "4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
                            "& fieldset": {
                              borderRadius: "30px",
                              border: "none",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "18px",
                          },
                        }}
                      />

                      <CustomTextarea
                        id="outlined-basic"
                        placeholder="Task Description"
                        style={{
                          height: "300px",
                          borderRadius: "30px",
                          border: "1px solid #F5F5F5",
                          boxShadow:
                            "4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
                          fontSize: "18px",
                        }}
                        onChange={(e) => handleTaskDescription(e.target.value)}
                      />
                    </div>

                    <div className={styles.modalTopLeft}>
                      <CustomTextarea
                        required
                        id="outlined-basic"
                        placeholder="Message to your Supporter. Example) Thank you for supporting my task :)"
                        style={{
                          height: "400px",
                          borderRadius: "30px",
                          border: "1px solid #F5F5F5",
                          boxShadow:
                            "4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
                          fontSize: "24px",
                        }}
                        onChange={(e) =>
                          handleFirstMessageToSupporter(e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <DatePickerMUI
                    date={scheduledTaskDate}
                    startingTime={"19:30"}
                    endingTime={"20:30"}
                    onChangeDate={(event: any) => {
                      const newDate = returnSelectedDate(event);
                      setScheduledTaskDate(newDate);
                    }}
                    onChangeStartTime={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) => setTime(event.target.value, "start")}
                    onChangeEndTime={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) => setTime(event.target.value, "end")}
                  />
                  <div className={styles.buttonWrapper}>
                    <button className={`${styles.button} ${styles.editButton}`}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </ClickAwayListener>
          </motion.div>
        </FormControl>
      </form>
    </AnimatePresence>
  );
};

const getToday1930 = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();

  const today2030 = new Date(year, month, day, 19, 30, 0, 0);

  return today2030.getTime();
};
const getToday2030 = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();

  const today2030 = new Date(year, month, day, 20, 30, 0, 0);

  return today2030.getTime();
};
