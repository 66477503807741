import APIcore, { apiRequest } from "./core/APIcore";
import { fetchMyTaskListRequest } from "./fetchMyTaskListRequest";

type deleteTaskRequestProps = {
  taskId: number;
  setTaskList: any;
};

export const deleteTaskRequest = async (props: deleteTaskRequestProps) => {
  const taskId = props.taskId;

  const apiDelete: apiRequest = {
    url: `users/Id/tasks/${taskId}`,
  };
  const response = await APIcore.delete(apiDelete);

  if (response.status === 200) {
    const fetchTaskListFromAPIProps = {
      setTaskList: props.setTaskList,
    };
    fetchMyTaskListRequest(fetchTaskListFromAPIProps);
  }
};
