import { createUserRequest } from "./createUserRequest";

type googleSignUpProps = {
  userName: string;
  cognito_id: string;
};

export const googleSignUp = async (props: googleSignUpProps) => {
  const createUserProps = {
    userName: props.userName,
    cognito_id: props.cognito_id,
  };
  const response = await createUserRequest(createUserProps);
};
