import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Home } from "../pages/Home/Home";
import { Task } from "../components/organisms/Task/Task";
import { useEffect, useState } from "react";
import { SignUp } from "../pages/SignUp/SignUp";
import { ConfirmRegistration } from "../pages/ConfirmRegistration/ConfirmRegistration";
import { Login } from "../pages/Login/Login";
import { checkToken } from "../utils/Token/checkToken";
import { googleLogin } from "../services/googleLogin";
import { Ring } from "@uiball/loaders";
import { FinishedTask } from "../components/organisms/FinishedTask/FinishedTask";
import { Settings } from "../components/organisms/Settings/Settings";

export type userInfoType = {
  readonly userName: string | null;
  readonly userId: number | null;
};

const Routers = () => {
  const [status, setStatus] = useState("not_loggedIn");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);

  const [userInfo, setUserInfo] = useState<userInfoType>({
    userId: null,
    userName: null,
  });

  const [userName, setUserName] = useState("");
  const checkGoogleAndCognitoToken = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const googleCode = searchParams.get("code");

    if (googleCode) {
      const googleLoginProps = {
        code: googleCode,
        userName: userName,
        setUserName: setUserName,
      };
      await googleLogin(googleLoginProps);
    }
    setLoading(false);
    await checkToken(setStatus);
  };
  useEffect(() => {
    checkGoogleAndCognitoToken();
  }, []);

  return (
    <BrowserRouter>
      {loading ? (
        <div style={{ height: "100vh", display: "grid", placeItems: "center" }}>
          <Ring size={80} lineWeight={5} speed={2} color="black" />
        </div>
      ) : (
        <Routes>
          {status === "not_loggedIn" && (
            <Route>
              <Route
                path="/signup"
                element={<SignUp setStatus={setStatus} setEmail={setEmail} />}
              />
              <Route path="/login" element={<Login setStatus={setStatus} />} />
              <Route path="/" element={<Login setStatus={setStatus} />} />
              <Route
                path="/confirm_registration"
                element={
                  <ConfirmRegistration setStatus={setStatus} email={email} />
                }
              />

              <Route path="*" element={<Navigate to="/login" replace />} />
            </Route>
          )}
          {status === "loggedIn" && (
            <>
              <Route
                path="/"
                element={
                  <Home
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    setStatus={setStatus}
                  />
                }
              >
                <Route path="/" element={<Task userInfo={userInfo} />} />
                <Route
                  path="/finished"
                  element={<FinishedTask userInfo={userInfo} />}
                />
                <Route
                  path="/account"
                  element={
                    <Settings
                      userName={userInfo.userName}
                      setUserInfo={setUserInfo}
                    />
                  }
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </>
          )}
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default Routers;
