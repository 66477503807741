import { MessageContainer } from "../Modal/MessageContainer/MessageContainer";
import styles from "./style.module.css";
import { motion } from "framer-motion";
import { userInfoType } from "../../../routers";
import { messageListType } from "../../../services/sendMessageRequest";
import { TaskListType, TaskMessageListType } from "../../organisms/Task/Task";
import { Ring } from "@uiball/loaders";

type TaskCardProps = {
  taskStatus: "ongoing" | "finished";
  userInfo: userInfoType;
  setSelectedTaskId: React.Dispatch<React.SetStateAction<number | null>>;
  taskList: TaskListType[];
  messagesList: messageListType[];
  setMessagesList: React.Dispatch<React.SetStateAction<messageListType[]>>;
  taskMessageList: TaskMessageListType[];
  setTaskMessageList: React.Dispatch<
    React.SetStateAction<TaskMessageListType[]>
  >;
  setIsMessageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditTaskAddModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
};

// !APIは一緒だけど、クエリで変える

export const TaskCard = (props: TaskCardProps) => {
  const handleSetMessages = (taskId: number) => {
    const filteredArray = props.messagesList
      ? props.messagesList.filter((obj: any) => obj.task_id === taskId)
      : [];
    props.setTaskMessageList!(filteredArray);

    props.setIsMessageModalOpen(true);
    props.setSelectedTaskId(taskId);
  };
  const handleIsEditTaskAddModalOpen = (taskId: number) => {
    props.setSelectedTaskId(taskId);
    props.setIsEditTaskAddModalOpen(true);
  };
  const convertToCurrentTime = (time: any) => {
    if (!time) return;
    const timeStamp = Number(time);
    const localTimeStamp = new Date(timeStamp);
    const hours = localTimeStamp.getHours();
    const minutes = localTimeStamp.getMinutes();

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return formattedTime;
  };
  const taskExpired = (time: number) => {
    const timeStamp = Number(time);
    const currentTime = new Date().getTime();
    if (timeStamp < currentTime) {
      return styles.expiredTaskWrapper;
    }
    return styles.taskCardWrapper;
  };
  const currentTime = new Date().getTime();
  return (
    <>
      {props.taskList.length > 0 ? (
        <div>
          <p className={styles.taskCount}>
            {props.taskStatus === "ongoing"
              ? "Ongoing task count："
              : "Finished task count："}
            {props.taskList.length}
          </p>
          <div className={styles.TaskListWrapper}>
            {props.taskList.map((list) => {
              return (
                <div key={list.id} className={taskExpired(list.end_time)}>
                  <div className={styles.taskCardTopWrapper}>
                    <motion.div
                      className={styles.taskCardTop}
                      onClick={() => handleIsEditTaskAddModalOpen(list.id)}
                    >
                      <div className={styles.taskCardTitleWrapper}>
                        <p className={styles.taskCardTitle}>{list.title}</p>
                      </div>
                      <div className={styles.taskCardInfo}>
                        <div className={styles.taskTime}>
                          {list.date}　{convertToCurrentTime(list.start_time)}{" "}
                          〜{convertToCurrentTime(list.end_time)}
                        </div>
                        {list.supporter_id ? (
                          <div className={styles.supporterName}>
                            Your Supporter：{list.name}
                          </div>
                        ) : (
                          <div className={styles.supporterName}>
                            not assigned yet
                          </div>
                        )}
                        {currentTime > list.end_time ? (
                          <div>overdue</div>
                        ) : null}
                      </div>
                    </motion.div>
                  </div>
                  <motion.div key={list.id} className={styles.taskCardBottom}>
                    <motion.div
                      layoutId={`${list.id}`}
                      onClick={() => handleSetMessages(list.id)}
                    >
                      <MessageContainer
                        userInfo={props.userInfo}
                        supporterName={list.name}
                        senderId={props.userInfo.userId!}
                        receiverId={list.supporter_id}
                        taskId={list.id}
                        messagesList={props.messagesList}
                        setMessagesList={props.setMessagesList}
                      />
                    </motion.div>
                  </motion.div>
                </div>
              );
            })}
          </div>
        </div>
      ) : props.loading ? (
        <div style={{ height: "80vh", display: "grid", placeItems: "center" }}>
          <Ring size={80} lineWeight={5} speed={2} color="black" />
        </div>
      ) : (
        <div>
          <p className={styles.taskCount}>
            {props.taskStatus === "ongoing"
              ? "Ongoing task count："
              : "Finished task count："}
            {props.taskList.length}
          </p>
          <div className={styles.withoutTask}>No task yet</div>
        </div>
      )}
    </>
  );
};
