import { supportingTaskType } from "../components/molecules/PeopleTaskCard/PeopleTaskCard";
import APIcore, { apiRequest } from "./core/APIcore";

type fetchTaskListFromAPIProps = {
  setSupportingTaskList: React.Dispatch<
    React.SetStateAction<supportingTaskType[]>
  >;
};

export const fetchSupportingTaskListRequest = (
  props: fetchTaskListFromAPIProps
) => {
  const fetchTaskList = async () => {
    const apiGet: apiRequest = {
      url: `users/id/supportingtasks`,
    };
    const response = await APIcore.get(apiGet);
    const data = await response.json();
    props.setSupportingTaskList(data);
  };
  fetchTaskList();
};
