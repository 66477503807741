import React from "react";

interface Props {
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}
export const GoogleSignInButton = (props: Props) => {
  const buttonStyle: React.CSSProperties = {
    display: "inline-block",
    color: "#379efd",
    backgroundColor: "#ffffff",

    padding: "1rem",
    textDecoration: "none",
    fontSize: "16px",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
    transition: "0.5s",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  };

  const hoverStyle = {
    color: "#ffffff",
    backgroundColor: "#379efd",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
    transition: "0.5s",
  };

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };

  const [isHovered, setHovered] = React.useState(false);
  const uri = process.env.REACT_APP_REDIRECT_URL!;
  const encoded = encodeURIComponent(uri);
  return (
    <a
      href={`https://terraform-mythril-${process.env.REACT_APP_COGNITO_ENV}.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?client_id=${process.env.REACT_APP_COGNITO_GOOGLE_USER_POOL_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${encoded}&identity_provider=Google`}
      style={isHovered ? { ...buttonStyle, ...hoverStyle } : buttonStyle}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      Login with Google
    </a>
  );
};
