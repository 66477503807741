import React, { useState } from "react";
import styles from "./style.module.css";
import { getUserPool } from "../../../config";
import { useNavigate } from "react-router-dom";
import ClickAwayListener from "react-click-away-listener";
import { motion, AnimatePresence } from "framer-motion";

type HamburgerMenuProps = {
  setStatus: React.Dispatch<React.SetStateAction<string>>;
};

export const HamburgerMenu = (props: HamburgerMenuProps) => {
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);

  const toggleMenu = () => {
    setIsSettingModalOpen(!isSettingModalOpen);
  };

  const navigate = useNavigate();
  const logout = (link: string) => {
    if (window.confirm("Are you sure you want to logout?")) {
      props.setStatus("not_loggedIn");
      const userPool = getUserPool("COGNITO");
      const currentUser = userPool.getCurrentUser();
      if (currentUser !== null) {
        console.log("COGNITO SIGN OUT");
        const cognitoUser = userPool.getCurrentUser();
        cognitoUser?.signOut();
      } else {
        console.log("GOOGLE SIGN OUT");
        const userPool = getUserPool("GOOGLE");
        const cognitoUser = userPool?.getCurrentUser();
        cognitoUser?.signOut();
      }
      navigate("/login");
    }
  };

  const handleCloseModal = () => {
    setIsSettingModalOpen(false);
  };
  return (
    <>
      <div
        className={`${styles.hamburgerMenu} ${
          isSettingModalOpen ? styles.menuOpen : ""
        }`}
        onClick={toggleMenu}
      >
        <span className={styles.line}></span>
        <span className={styles.line}></span>
        <span className={styles.line}></span>
      </div>
      {isSettingModalOpen && (
        <AnimatePresence>
          <ClickAwayListener onClickAway={handleCloseModal}>
            <motion.div
              className={`${styles.menu} ${styles.toggleOn}`}
              key="modal"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              exit={{ opacity: 0 }}
            >
              {menuList.map((list, index: number) => (
                <button
                  className={styles.menuList}
                  key={index}
                  onClick={() =>
                    list.title === "Logout"
                      ? logout(list.link)
                      : navigate(list.link)
                  }
                >
                  {list.title}
                </button>
              ))}
            </motion.div>
          </ClickAwayListener>
        </AnimatePresence>
      )}
    </>
  );
};

type menuListType = {
  title: string;
  title_ja: string;
  className: string;
  link: string;
};

const menuList: menuListType[] = [
  {
    title: "Finished Tasks",
    title_ja: "完了済みタスク",
    className: "finishedTask",
    link: "/finished",
  },
  {
    title: "Account",
    title_ja: "アカウント",
    className: "account",
    link: "/account",
  },
  {
    title: "Logout",
    title_ja: "ログアウト",
    className: "logout",
    link: "/login",
  },
];
