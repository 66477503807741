import { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { FormControl, TextField } from "@mui/material";
import { TextareaAutosize } from "@mui/material";
import { styled } from "@mui/system";
import { DatePickerMUI } from "../../../library/DatePicker/DatePickerMUI";
import { getTodayDate, returnSelectedDate } from "../../../../utils/Date/date";
import Swal from "sweetalert2";
import { fetchMyTaskListRequest } from "../../../../services/fetchMyTaskListRequest";
import { TaskListType } from "../../../organisms/Task/Task";
import { messageListType } from "../../../../services/sendMessageRequest";
import { userInfoType } from "../../../../routers";
import { deleteTaskRequest } from "../../../../services/deleteTaskRequest";
import {
  editTaskRequest,
  editTaskRequestProps,
} from "../../../../services/editTaskRequest";
import ClickAwayListener from "react-click-away-listener";
import { finishTaskRequest } from "../../../../services/finishTaskRequest";
import { AnimatePresence, motion } from "framer-motion";

// MUIのカスタムCSSを適応させてる
const CustomTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  padding: "2rem",
  borderRadius: "30px",
  boxShadow: "0px 0px 15px rgb(17 21 26 / 15%)",
  resize: "none",
  border: "none",
  outline: "none",
  backgroundColor: "#ffffff",
  fontSize: "24px",
  fontFamily: "inherit",
}));

type TaskModalProps = {
  userInfo: userInfoType;
  taskId: number | null;
  taskList: TaskListType[];
  setTaskList: React.Dispatch<React.SetStateAction<TaskListType[]>>;
  setIsEditTaskAddModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTaskId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedTaskId: number | null;
  setMessagesList: React.Dispatch<React.SetStateAction<messageListType[]>>;
};

export const EditTaskModal = (props: TaskModalProps) => {
  const today = getTodayDate();
  const [scheduledTaskDate, setScheduledTaskDate] = useState(today);
  const getToday1930 = (scheduledTaskDate: any) => {
    const date = new Date(scheduledTaskDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const today1930 = new Date(year, month, day, 19, 30, 0, 0);
    return today1930.getTime();
  };

  const getToday2030 = (scheduledTaskDate: string) => {
    const date = new Date(scheduledTaskDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const today2030 = new Date(year, month, day, 20, 30, 0, 0);

    return today2030.getTime();
  };
  const selectedTaskInfo = props.taskList
    ? props.taskList.filter((obj: any) => obj.id === props.selectedTaskId)
    : [];
  const [taskTitle, setTaskTitle] = useState(selectedTaskInfo[0].title);
  const [taskDescription, setTaskDescription] = useState(
    selectedTaskInfo[0].description || ""
  );
  const [startTime, setStartTime] = useState<number>(
    getToday1930(scheduledTaskDate)
  );
  const [endTime, setEndTime] = useState<number>(
    getToday2030(scheduledTaskDate)
  );

  const handleTaskTitle = (event: string) => {
    setTaskTitle(event);
  };

  const handleTaskDescription = (event: string) => {
    setTaskDescription(event);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (event.target[0].value.length > 40) {
      Swal.fire({
        icon: "error",
        title: "Please enter a task within 40 characters",
      });
      return;
    }
    const taskId: number = props.selectedTaskId!;
    const action = event.nativeEvent.submitter.value;
    if (action === "edit") {
      Swal.fire({
        icon: "question",
        title: "Is this okay?",
        html: `Task Title：${taskTitle} <br><br> Description ：${taskDescription}
      `,
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          const editTaskRequestData: editTaskRequestProps = {
            taskId: taskId,
            taskTitle: taskTitle,
            taskDescription: taskDescription,
            taskDate: scheduledTaskDate,
            setTaskList: props.setTaskList,
            startTime: startTime,
            endTime: endTime,
          };
          editTaskRequest(editTaskRequestData);
          props.setIsEditTaskAddModalOpen(false);
          props.setSelectedTaskId(null);
        }
      });
      props.setIsEditTaskAddModalOpen(false);
    } else if (action === "delete") {
      const isOkay = window.confirm(
        `Are you sure you want to delete this task?`
      );
      if (isOkay) {
        console.log("delete");
        const deleteTaskRequestData = {
          taskId: taskId,
          setTaskList: props.setTaskList,
        };
        deleteTaskRequest(deleteTaskRequestData);
        props.setIsEditTaskAddModalOpen(false);
        props.setSelectedTaskId(null);
      }
    } else if (action === "Finish") {
      const isOkay = window.confirm(`Finished this Task??`);
      if (isOkay) {
        const finishTaskRequestData = {
          taskId: taskId,
          setTaskList: props.setTaskList,
        };
        finishTaskRequest(finishTaskRequestData);
        props.setIsEditTaskAddModalOpen(false);
        props.setSelectedTaskId(null);
      }
    }
  };

  const handleCloseModal = () => {
    props.setIsEditTaskAddModalOpen(false);
  };

  const setTime = (event: any, startOrEnd: string) => {
    const convertTimeStringToTimestamp = (
      timeString: string,
      dateString: string
    ) => {
      const [hours, minutes] = timeString.split(":").map(Number);
      const date = new Date(dateString);
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date.getTime();
    };

    const timestamp = convertTimeStringToTimestamp(event, scheduledTaskDate);
    if (startOrEnd === "start") {
      setStartTime(timestamp);
    } else {
      setEndTime(timestamp);
    }
  };
  useEffect(() => {
    setStartTime(getToday1930(scheduledTaskDate));
    setEndTime(getToday2030(scheduledTaskDate));
  }, [scheduledTaskDate]);

  return (
    <AnimatePresence>
      <form className={styles.form} onSubmit={handleSubmit}>
        <FormControl>
          <div className={styles.modalWrapper}>
            <ClickAwayListener onClickAway={handleCloseModal}>
              <motion.div
                className={styles.modal}
                key="modal"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                }}
                exit={{ opacity: 0 }}
              >
                <div className={styles.modelGap}>
                  <div className={styles.modalTop}>
                    <div className={styles.modalTopRight}>
                      <TextField
                        required
                        id="outlined-basic"
                        label={"Task Title"}
                        value={taskTitle}
                        variant="outlined"
                        onChange={(e) => handleTaskTitle(e.target.value)}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            padding: "1rem",
                            fontSize: "18px",
                            borderRadius: "30px",
                            boxShadow:
                              "4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
                            "& fieldset": {
                              borderRadius: "30px",
                              border: "none",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "18px",
                          },
                        }}
                      />
                      <CustomTextarea
                        id="outlined-basic"
                        placeholder="Task Description / how you felt after the task"
                        value={taskDescription!}
                        style={{
                          height: "300px",
                          borderRadius: "30px",
                          border: "1px solid #F5F5F5",
                          boxShadow:
                            "4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
                          fontSize: "24px",
                        }}
                        onChange={(e) => handleTaskDescription(e.target.value)}
                      />
                    </div>
                  </div>
                  <DatePickerMUI
                    date={scheduledTaskDate}
                    startingTime={"19:30"}
                    endingTime={"20:30"}
                    onChangeDate={(event: any) => {
                      const newDate = returnSelectedDate(event);
                      setScheduledTaskDate(newDate);
                    }}
                    onChangeStartTime={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) => setTime(event.target.value, "start")}
                    onChangeEndTime={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) => setTime(event.target.value, "end")}
                  />
                </div>
                <div className={styles.buttonWrapper}>
                  <button
                    className={`${styles.button} ${styles.editButton}`}
                    type="submit"
                    value="edit"
                  >
                    Edit
                  </button>
                  <button
                    className={`${styles.button} ${styles.finishButton}`}
                    type="submit"
                    value="Finish"
                  >
                    Finish
                  </button>
                  <input type="hidden" name="action" value="delete" />
                  <button
                    className={`${styles.button} ${styles.deleteButton}`}
                    type="submit"
                    value="delete"
                  >
                    Delete
                  </button>
                </div>
              </motion.div>
            </ClickAwayListener>
          </div>
        </FormControl>
      </form>
    </AnimatePresence>
  );
};
