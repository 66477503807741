import { useRef, useState } from "react";
import styles from "./style.module.css";
import { FormControl, TextField } from "@mui/material";
import { TextareaAutosize } from "@mui/material";
import { styled } from "@mui/system";
import { DatePickerMUI } from "../../../library/DatePicker/DatePickerMUI";
import { getTodayDate, returnSelectedDate } from "../../../../utils/Date/date";
import Swal from "sweetalert2";
import { fetchMyTaskListRequest } from "../../../../services/fetchMyTaskListRequest";
import { TaskListType } from "../../../organisms/Task/Task";
import { messageListType } from "../../../../services/sendMessageRequest";
import { userInfoType } from "../../../../routers";
import { deleteTaskRequest } from "../../../../services/deleteTaskRequest";
import {
  editTaskRequest,
  editTaskRequestProps,
} from "../../../../services/editTaskRequest";
import ClickAwayListener from "react-click-away-listener";
import { supportingTaskType } from "../../PeopleTaskCard/PeopleTaskCard";
import { AnimatePresence, motion } from "framer-motion";

// MUIのカスタムCSSを適応させてる
const CustomTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  padding: "2rem",
  borderRadius: "30px",
  boxShadow: "0px 0px 15px rgb(17 21 26 / 15%)",
  resize: "none",
  border: "none",
  outline: "none",
  backgroundColor: "#ffffff",
  fontSize: "24px",
  fontFamily: "inherit",
}));

type PeopleTaskModalProps = {
  userInfo: userInfoType;
  supportingTaskList: supportingTaskType[];
  selectedTaskId: number | null;
  setIsPeopleTaskModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PeopleTaskModal = (props: PeopleTaskModalProps) => {
  const handleCloseModal = () => {
    props.setIsPeopleTaskModalOpen(false);
  };

  const supportingTaskInfo: supportingTaskType[] = props.supportingTaskList
    ? props.supportingTaskList.filter(
        (obj: any) => obj.id === props.selectedTaskId
      )
    : [];

  const convertToCurrentTime = (time: any) => {
    if (!time) return;
    const timeStamp = Number(time);
    const localTimeStamp = new Date(timeStamp);
    const hours = localTimeStamp.getHours();
    const minutes = localTimeStamp.getMinutes();

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return formattedTime;
  };
  console.log(props.supportingTaskList);
  console.log(supportingTaskInfo);

  return (
    <AnimatePresence>
      <motion.div
        className={styles.modalWrapper}
        key="modal"
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
      >
        <ClickAwayListener onClickAway={handleCloseModal}>
          <div className={styles.modal}>
            <div className={styles.modelGap}>
              <div className={styles.modalTop}>
                <div className={styles.modalTopRight}>
                  <div className={styles.supporterName}>
                    {supportingTaskInfo[0].created_user}
                  </div>
                  <div className={styles.supporterTaskTitle}>
                    {supportingTaskInfo[0].title}
                  </div>
                  <div className={styles.supporterTaskDescription}>
                    {supportingTaskInfo[0].description}
                  </div>
                  <div className={styles.date}>
                    Date：{supportingTaskInfo[0].date}
                  </div>
                  <div className={styles.time}>
                    Time：
                    {convertToCurrentTime(supportingTaskInfo[0].start_time)} ~
                    {convertToCurrentTime(supportingTaskInfo[0].end_time)}
                  </div>
                  <div className={styles.duration}>
                    Duration：{supportingTaskInfo[0].hour} hour and{" "}
                    {supportingTaskInfo[0].minute} minute
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </motion.div>
    </AnimatePresence>
  );
};
