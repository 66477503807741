import APIcore, { apiRequest } from "./core/APIcore";
import { messageListType } from "./sendMessageRequest";

type fetchMessagesFromAPIProps = {
  setMessagesList: React.Dispatch<React.SetStateAction<messageListType[]>>;
  taskI?: number;
};

export const fetchMessagesRequest = (props: fetchMessagesFromAPIProps) => {
  const fetchMessages = async () => {
    const apiGet: apiRequest = {
      url: `messages`,
    };
    const response = await APIcore.get(apiGet);
    const data = await response.json();
    props.setMessagesList(data);
  };
  fetchMessages();
};
