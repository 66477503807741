import { TaskListType } from "../components/organisms/Task/Task";
import APIcore, { apiRequest } from "./core/APIcore";
import { fetchMyTaskListRequest } from "./fetchMyTaskListRequest";

export type editTaskRequestProps = {
  taskId: number;
  taskTitle: string;
  taskDescription: string | null;
  taskDate: string;
  setTaskList: React.Dispatch<React.SetStateAction<TaskListType[]>>;
  startTime: number;
  endTime: number;
};
export const editTaskRequest = async (props: editTaskRequestProps) => {
  const updatedDate = Date.now();
  const taskId = props.taskId;
  const startTimestamp = Number(props.startTime);
  const endTimestamp = Number(props.endTime);
  const timeDifference = endTimestamp - startTimestamp;
  const differenceInHours = Math.floor(timeDifference / (1000 * 60 * 60));
  const differenceInMinutes = Math.floor(
    (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
  );

  const apiPatch: apiRequest = {
    url: `users/id/tasks/${taskId}`,
    requestItems: {
      title: props.taskTitle,
      description: props.taskDescription,
      date: props.taskDate,
      hour: differenceInHours,
      minute: differenceInMinutes,
      updated_at: updatedDate,
      task_id: props.taskId,
      startTime: props.startTime,
      endTime: props.endTime,
    },
  };

  const response = await APIcore.patch(apiPatch);

  // TODO ここでリストを取るフェッチを走らせる（リロード関数とか作ると汎用性あるかもね）
  if (response.status === 200) {
    const fetchTaskListFromAPIProps = {
      setTaskList: props.setTaskList,
    };
    fetchMyTaskListRequest(fetchTaskListFromAPIProps);
  }
};
