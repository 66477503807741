import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

export type AccountType = "GOOGLE" | "COGNITO";

// accountTypeが両方時の処理も必要
export const getUserPool = (accountType: AccountType) => {
  const clientId =
    accountType === "COGNITO"
      ? process.env.REACT_APP_ONLY_COGNITO_USER_POOL_CLIENT_ID || ""
      : process.env.REACT_APP_COGNITO_GOOGLE_USER_POOL_CLIENT_ID || "";

  const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || "",
    ClientId: clientId,
  };

  return new AmazonCognitoIdentity.CognitoUserPool(poolData);
};
