import { useEffect, useState } from "react";
import styles from "./style.module.css";
import { AddTaskModal } from "../../molecules/Modal/AddTaskModal/AddTaskModal";
import { TaskCard } from "../../molecules/TaskCard/TaskCard";
import {
  PeopleTaskCard,
  supportingTaskType,
} from "../../molecules/PeopleTaskCard/PeopleTaskCard";
import { motion, AnimatePresence } from "framer-motion";
import ClickAwayListener from "react-click-away-listener";
import { userInfoType } from "../../../routers";
import Swal from "sweetalert2";
import {
  messageDataRequestType,
  messageListType,
  messageType,
  sendMessageRequest,
} from "../../../services/sendMessageRequest";
import { fetchMyTaskListRequest } from "../../../services/fetchMyTaskListRequest";
import { fetchMessagesRequest } from "../../../services/fetchMessagesRequest";
import { EditTaskModal } from "../../molecules/Modal/EditTaskModal/EditTaskModal";
import { MessageModal } from "../../molecules/Modal/MessageModal/MessageModal";
import { PeopleTaskModal } from "../../molecules/Modal/PeopleTaskModal/PeopleTaskModal";
import { fetchSupportingTaskListRequest } from "../../../services/fetchSupportingTaskListRequest";
import { useNavigate } from "react-router-dom";
import { Ring } from "@uiball/loaders";
import { EditFinishedTaskModal } from "../../molecules/Modal/EditFinishedTaskModal/EditFinishedTaskModal";
import { TaskListType } from "../Task/Task";

type TaskProps = {
  userInfo: userInfoType;
};

export type TaskMessageListType = {
  created_at: "";
  deleted_at: "" | null;
  id: number;
  message: string;
  receiver_id: number;
  sender_id: number;
  task_id: number;
  updated_id: "" | null;
};

// ! API側でパラメーター管理。受け取る側では全て定義する

export const FinishedTask = (props: TaskProps) => {
  const [taskList, setTaskList] = useState<TaskListType[]>([]);
  const [supportingTaskList, setSupportingTaskList] = useState<
    supportingTaskType[]
  >([]);
  const [messagesList, setMessagesList] = useState<messageListType[]>([]);
  const [selectedTaskMessageList, setSelectedTaskMessageList] = useState<
    TaskMessageListType[]
  >([]);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isTaskAddModalOpen, setIsTaskAddModalOpen] = useState(false);
  const [isEditTaskAddModalOpen, setIsEditTaskAddModalOpen] = useState(false);
  const [isPeopleTaskModalOpen, setIsPeopleTaskModalOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleAddTask = () => {
    const filteredTaskList = taskList.filter((item) => item.status !== 2);
    if (filteredTaskList.length >= 5) {
      window.alert("Can't create more than 5 tasks");
      return;
    }
    setIsTaskAddModalOpen(true);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const googleCode = searchParams.get("code");
    googleCode && navigate("/");
    const fetchData = async () => {
      const fetchTaskListFromAPIProps = {
        setTaskList: setTaskList,
      };
      fetchMyTaskListRequest(fetchTaskListFromAPIProps);

      const fetchSupportingTaskListFromAPIProps = {
        setSupportingTaskList: setSupportingTaskList,
      };
      fetchSupportingTaskListRequest(fetchSupportingTaskListFromAPIProps);

      const fetchMessagesFromAPIProps = {
        setMessagesList: setMessagesList,
      };
      fetchMessagesRequest(fetchMessagesFromAPIProps);

      setLoading(false);
    };
    fetchData();
  }, []);
  const filteredTaskList = taskList.filter((item) => item.status === 2);
  const filteredPeopleTaskList = supportingTaskList.filter(
    (item) => item.status === 2
  );

  return (
    <>
      {loading ? (
        <div style={{ height: "80vh", display: "grid", placeItems: "center" }}>
          <Ring size={80} lineWeight={5} speed={2} color="black" />
        </div>
      ) : (
        <div>
          <div className={styles.taskCard}>
            <TaskCard
              taskStatus={"finished"}
              userInfo={props.userInfo}
              setSelectedTaskId={setSelectedTaskId}
              taskList={filteredTaskList}
              messagesList={messagesList}
              setMessagesList={setMessagesList}
              taskMessageList={selectedTaskMessageList}
              setTaskMessageList={setSelectedTaskMessageList}
              setIsMessageModalOpen={setIsMessageModalOpen}
              setIsEditTaskAddModalOpen={setIsEditTaskAddModalOpen}
              loading={loading}
            />

            <PeopleTaskCard
              taskStatus={"finished"}
              userInfo={props.userInfo}
              setSelectedTaskId={setSelectedTaskId}
              supportingTaskList={filteredPeopleTaskList}
              messagesList={messagesList}
              setMessagesList={setMessagesList}
              taskMessageList={selectedTaskMessageList}
              setTaskMessageList={setSelectedTaskMessageList}
              setIsMessageModalOpen={setIsMessageModalOpen}
              setIsPeopleTaskModalOpen={setIsPeopleTaskModalOpen}
            />
          </div>
          <MessageModal
            userInfo={props.userInfo}
            taskList={taskList}
            supportingTaskList={supportingTaskList}
            selectedTaskId={selectedTaskId}
            isMessageModalOpen={isMessageModalOpen}
            setIsMessageModalOpen={setIsMessageModalOpen}
            selectedTaskMessageList={selectedTaskMessageList}
            setMessagesList={setMessagesList}
          />

          {/* {isTaskAddModalOpen && (
            <AddTaskModal
              setIsTaskAddModalOpen={setIsTaskAddModalOpen}
              taskList={taskList}
              setTaskList={setTaskList!}
              userInfo={props.userInfo}
              taskId={selectedTaskId}
              setMessagesList={setMessagesList}
            />
          )} */}

          {isEditTaskAddModalOpen && (
            <EditFinishedTaskModal
              setIsEditTaskAddModalOpen={setIsEditTaskAddModalOpen}
              taskList={taskList}
              setTaskList={setTaskList!}
              selectedTaskId={selectedTaskId}
              setSelectedTaskId={setSelectedTaskId}
              userInfo={props.userInfo}
              taskId={selectedTaskId}
              setMessagesList={setMessagesList}
            />
          )}
          {isPeopleTaskModalOpen && (
            <PeopleTaskModal
              supportingTaskList={supportingTaskList}
              selectedTaskId={selectedTaskId}
              userInfo={props.userInfo}
              setIsPeopleTaskModalOpen={setIsPeopleTaskModalOpen}
            />
          )}
        </div>
      )}
    </>
  );
};
