import APIcore, { apiRequest } from "./core/APIcore";

export const fetchUserNameRequest = async () => {
  const apiPost: apiRequest = {
    url: "users",
  };
  const response = await APIcore.get(apiPost);
  const data = await response.json();

  if (response.status === 200) {
    return data[0];
  } else {
    return null;
  }
};
