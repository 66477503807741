import { AccountType, getUserPool } from "../../config";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

export const checkToken = async (
  setStatus: React.Dispatch<React.SetStateAction<string>>
) => {
  const isToken = await checkTokenByAccountType("GOOGLE", setStatus);
  if (isToken) {
    return;
  } else {
    await checkTokenByAccountType("COGNITO", setStatus);
  }
};

const checkTokenByAccountType = async (
  accountType: AccountType,
  setStatus: React.Dispatch<React.SetStateAction<string>>
) => {
  const session = await getCognitoSession(accountType, setStatus);

  if (session?.isValid()) {
    console.log("トークンが有効です");
    setStatus("loggedIn");
    return true;
  } else {
    console.log("トークンが存在しません");
    setStatus("not_loggedIn");
    return false;
  }
};

const getCognitoSession = (
  accountType: AccountType,
  setStatus: React.Dispatch<React.SetStateAction<string>>
): Promise<CognitoSession | undefined> => {
  return new Promise<CognitoSession | undefined>((resolve, reject) => {
    const userPool = getUserPool(accountType);
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      console.log(
        "getCognitoSessionしてcognitoUserが存在しません。トークンが存在しません。"
      );
      resolve(undefined);
      return;
    }
    cognitoUser.getSession(
      (err: any, result: AmazonCognitoIdentity.CognitoUserSession) => {
        if (err || !result) {
          reject(new Error("Failure getting Cognito session: " + err));
          return;
        }

        const session: CognitoSession = {
          credentials: {
            accessToken: result.getAccessToken().getJwtToken(),
            idToken: result.getIdToken().getJwtToken(),
            refreshToken: result.getRefreshToken().getToken(),
          },
          user: {
            userName: result.getIdToken().payload["cognito:username"],
            email: result.getIdToken().payload.email,
          },
          isValid: () => result.isValid(),
        };
        resolve(session);
        return;
      }
    );
  });
};

type CognitoSession = {
  credentials: {
    accessToken: string;
    idToken: string;
    refreshToken: string;
  };
  user: {
    userName: string;
    email: string;
  };
  isValid: () => boolean;
};
