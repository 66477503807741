import ClickAwayListener from "react-click-away-listener";
import styles from "./style.module.css";
import { AnimatePresence, motion } from "framer-motion";
import {
  messageDataRequestType,
  messageListType,
  messageType,
  sendMessageRequest,
} from "../../../../services/sendMessageRequest";
import { useState } from "react";
import {
  TaskListType,
  TaskMessageListType,
} from "../../../organisms/Task/Task";
import { userInfoType } from "../../../../routers";
import Swal from "sweetalert2";
import { supportingTaskType } from "../../PeopleTaskCard/PeopleTaskCard";
import { TextField } from "@mui/material";

type MessageModalProps = {
  userInfo: userInfoType;
  taskList: TaskListType[];
  supportingTaskList: supportingTaskType[];
  selectedTaskId: number | null;
  isMessageModalOpen: boolean;
  setIsMessageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTaskMessageList: TaskMessageListType[];
  setMessagesList: React.Dispatch<React.SetStateAction<messageListType[]>>;
};

export const MessageModal = (props: MessageModalProps) => {
  const [message, setMessage] = useState<messageType>(null);
  const handleMessage = (event: string) => {
    setMessage(event);
  };
  const handleClickAway = () => {
    props.setIsMessageModalOpen(false);
  };

  const sendMessage = () => {
    if (!message) {
      Swal.fire({
        icon: "error",
        title: "Please enter a message",
      });
      return;
    }
    Swal.fire({
      icon: "question",
      title: "Is this okay?",
      html: `Message：${message}`,
      showCancelButton: true,
    }).then((result) => {
      const selectedTask = props.taskList.find(
        (obj: any) => obj.id === props.selectedTaskId
      );
      const selectedSupportingTask = props.supportingTaskList.find(
        (obj: any) => obj.id === props.selectedTaskId
      );
      const sendMessagesProps: messageDataRequestType = {
        message: message,
        senderId: props.userInfo.userId!,
        receiverId: selectedTask
          ? selectedTask!.supporter_id
          : selectedSupportingTask!.supporter_id,
        taskId: props.selectedTaskId,
        setMessagesList: props.setMessagesList,
      };

      result.value && sendMessageRequest(sendMessagesProps);
      setMessage("");
    });
  };
  return (
    <AnimatePresence>
      {props.isMessageModalOpen && (
        <motion.div className={styles.modalWrapper}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <motion.div
              key="modal"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                width: 800,
                height: 700,
                position: "fixed",
                background: "#f6f6f6",
                borderRadius: 30,
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) ",
              }}
              exit={{ opacity: 0 }}
            >
              <motion.div className={styles.motionWrapper}>
                <motion.div className={styles.motionMessageModalTop}>
                  {props.selectedTaskMessageList &&
                    props.selectedTaskMessageList.map((taskMessageList) => {
                      return (
                        <motion.div
                          className={styles.taskMessageWrapper}
                          key={taskMessageList.id}
                        >
                          {props.userInfo.userId ===
                          taskMessageList.sender_id ? (
                            <>
                              <motion.h5 className={styles.yourMessage}>
                                <p className={styles.message}>
                                  {taskMessageList.message}
                                </p>
                                <p>：You😄</p>
                              </motion.h5>
                            </>
                          ) : (
                            <motion.h5 className={styles.supporterMessage}>
                              <motion.p>Supporter😄：</motion.p>
                              <motion.p className={styles.message}>
                                {taskMessageList.message}
                              </motion.p>
                            </motion.h5>
                          )}
                        </motion.div>
                      );
                    })}
                </motion.div>
                <motion.div className={styles.motionMessageModalBottom}>
                  <TextField
                    required
                    id="outlined-basic"
                    label={"message here"}
                    variant="outlined"
                    onChange={(e) => handleMessage(e.target.value)}
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      "& fieldset": { border: "none" },
                      boxShadow: "0px 0px 15px rgb(17 21 26 / 15%)",
                      fontFamily: "inherit",
                    }}
                  />
                  <motion.button
                    className={styles.sendButton}
                    onClick={sendMessage}
                  >
                    Send
                  </motion.button>
                </motion.div>
              </motion.div>
            </motion.div>
          </ClickAwayListener>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
