import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import styles from "./style.module.css";
import { fetchUserNameRequest } from "../../services/fetchUserName";
import { Header } from "../../components/organisms/Header/Header";
import { userInfoType } from "../../routers";
import { getUserPool } from "../../config";

interface HomeProps {
  userInfo: userInfoType;
  setUserInfo: React.Dispatch<React.SetStateAction<userInfoType>>;
  Task?: Event[];
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}

type SelectedMenuItemType =
  | "My Tasks"
  | "Supporting Tasks"
  | "Messages"
  | "Settings";

export const Home = (props: HomeProps) => {
  const [selectedMenuItem, setSelectedMenuItem] =
    useState<SelectedMenuItemType>("My Tasks");

  // !ここのどこか！
  useEffect(() => {
    const fetchUserInfo = async () => {
      const userData = await fetchUserNameRequest();
      if (userData) {
        props.setUserInfo((prev) => ({
          ...prev,
          userId: userData.id,
          userName: userData.name,
        }));
      } else {
        const userPool = getUserPool("COGNITO");
        const currentUser = userPool.getCurrentUser();
        if (currentUser !== null) {
          console.log("COGNITO SIGN OUT");
          const cognitoUser = userPool.getCurrentUser();
          cognitoUser?.signOut();
        } else {
          console.log("GOOGLE SIGN OUT");
          const userPool = getUserPool("GOOGLE");
          const cognitoUser = userPool?.getCurrentUser();
          cognitoUser?.signOut();
        }
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <>
      <div className={styles.mainWrapper}>
        <div className={styles.mainContentWrapper}>
          <Header
            userName={props.userInfo.userName}
            selectedMenuItem={selectedMenuItem}
            setSelectedMenuItem={setSelectedMenuItem}
            setStatus={props.setStatus}
          />
          <Outlet />
        </div>
        {/* <div className={styles.footerWrapper}>
          <p>©️ 2022-2024 Mythril</p>
        </div> */}
      </div>
    </>
  );
};
