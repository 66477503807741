import React, { useState } from "react";
import styles from "./style.module.css";
import { Alert, Button, Container, Stack, TextField } from "@mui/material";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { getUserPool } from "../../config";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { createUserRequest } from "../../services/createUserRequest";
import loginImage from "../../images/loginImage.jpg";

interface SignUpForm {
  email: string;
  password: string;
}

interface Props {
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

export const SignUp = (props: Props) => {
  const { register, handleSubmit } = useForm<SignUpForm>();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");

  const handleFetch = async (cognito_id: string) => {
    const now = new Date();
    const createUserProps = {
      userName: userName,
      created_at: now,
      delete_flag: 1,
      cognito_id: cognito_id,
    };
    const response = await createUserRequest(createUserProps);
  };

  const signUp: SubmitHandler<SignUpForm> = (data) => {
    const formValue = {
      email: data.email,
      password: data.password,
    };

    const attributeList = [];

    const dataEmail = {
      Name: "email",
      Value: data.email,
    };

    const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
      dataEmail
    );

    attributeList.push(attributeEmail);

    const validationData: AmazonCognitoIdentity.CognitoUserAttribute[] = [];

    const userPool = getUserPool("COGNITO");

    userPool.signUp(
      formValue.email,
      formValue.password,
      attributeList,
      validationData,
      (err, result) => {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        const cognito_id = result!.userSub;
        handleFetch(cognito_id);
        props.setEmail(data.email);
        navigate("/confirm_registration");
      }
    );
  };

  return (
    <div className={styles.loginPageWrapper}>
      <img src={loginImage} alt="loginImage" className={styles.image} />
      <div className={styles.loginForm}>
        <div>
          <p className={styles.loginMessage}>Welcome to</p>
          <p className={styles.loginMessage}>Mythril Ore!</p>
        </div>
        <div className={styles.inputWrapper}>
          <input
            type="name"
            className={styles.input}
            placeholder="Name"
            required
            onChange={(e) => setUserName(e.target.value)}
          />
          <input
            type="email"
            className={styles.input}
            placeholder="Email"
            required
            {...register("email")}
          />
          <input
            type="password"
            className={styles.input}
            placeholder="Password"
            required
            {...register("password")}
          />
        </div>

        <button className={styles.button} onClick={handleSubmit(signUp)}>
          SignUp
        </button>
        <div className={styles.signUpPageButton}>
          <p>Have an account already?</p>
          <a
            href="/login"
            className={styles.singUpPage}
            onClick={() => {
              props.setStatus("not_loggedIn");
            }}
          >
            Click here
          </a>
        </div>
      </div>
    </div>
  );
};
