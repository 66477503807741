import { TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import styles from "./style.module.css";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "15px",
    boxShadow: "0px 0px 15px rgb(17 21 26 / 15%)",
  },
}));

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "15px",
    boxShadow: "0px 0px 15px rgb(17 21 26 / 15%)",
    width: "300px",
  },
}));

const theme = createTheme();

type DatePickerProps = {
  date: string;
  startingTime: string;
  endingTime: string;
  // !any直せる？
  onChangeDate?: any;
  onChangeStartTime?: any;
  onChangeEndTime?: any;
};

// TODO anyを直す
export const DatePickerMUI = (props: DatePickerProps) => {
  const defaultDate = new Date();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      // TODO 言語設定で分岐する
      // adapterLocale={ja}
    >
      <div className={styles.datePickerWrapper}>
        {/* // TODO 過去の日時を選べないようにする */}
        <ThemeProvider theme={theme}>
          <CustomDatePicker
            label="DatePicker"
            value={props.date ? props.date : defaultDate}
            onChange={props.onChangeDate}
            renderInput={(params) => <TextField {...params} required />}
            inputFormat="yyyy/MM/dd"
            mask="____/__/__"
            sx={{ "& fieldset": { border: "none" } }}
          />
        </ThemeProvider>
        <div className={styles.timeWrapper}>
          <CustomTextField
            id="time"
            label="Start at"
            type="time"
            defaultValue={props.startingTime}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            sx={{ width: 150, "& fieldset": { border: "none" } }}
            onChange={props.onChangeStartTime}
            required
          />
          <CustomTextField
            id="time"
            label="Finish at"
            type="time"
            defaultValue={props.endingTime}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            sx={{ width: 150, "& fieldset": { border: "none" } }}
            onChange={props.onChangeEndTime}
            required
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};
