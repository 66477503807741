import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import womanDrawing from "../../../images/womanDrawing.png";
import manWorking from "../../../images/manWorking.png";
import { HamburgerMenu } from "../../atoms/HamburgerMenu/HamburgerMenu";

type SelectedMenuItemType =
  | "My Tasks"
  | "Supporting Tasks"
  | "Messages"
  | "Settings";

type HeaderProps = {
  userName: string | null;
  selectedMenuItem: SelectedMenuItemType;
  setSelectedMenuItem: React.Dispatch<
    React.SetStateAction<SelectedMenuItemType>
  >;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
};

export const Header = (props: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.headerWrapper}>
        <img src={womanDrawing} alt="womanDrawing" className={styles.image} />
        {/* <div className={styles.headerTaskTitle}>My Tasks</div> */}

        <div className={styles.userName} onClick={() => navigate("/")}>
          {props.userName ? props.userName : "No Name"}'s <br /> Home
        </div>

        {/* <div className={styles.headerTaskTitle}>Supporting Tasks</div> */}
        <img src={manWorking} alt="manWorking" className={styles.image} />
      </div>
      <div className={styles.hamburgerMenu}>
        <HamburgerMenu setStatus={props.setStatus} />
      </div>
    </>
  );
};
