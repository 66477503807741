import { MessageContainer } from "../Modal/MessageContainer/MessageContainer";
import styles from "./style.module.css";
import { motion } from "framer-motion";
import { userInfoType } from "../../../routers";
import { messageListType } from "../../../services/sendMessageRequest";
import { TaskMessageListType } from "../../organisms/Task/Task";

type PeopleTaskProps = {
  taskStatus: "ongoing" | "finished";
  userInfo: userInfoType;
  setSelectedTaskId: React.Dispatch<React.SetStateAction<number | null>>;
  supportingTaskList: supportingTaskType[];
  messagesList: messageListType[];
  setMessagesList: React.Dispatch<React.SetStateAction<messageListType[]>>;
  taskMessageList: TaskMessageListType[];
  setTaskMessageList: React.Dispatch<
    React.SetStateAction<TaskMessageListType[]>
  >;
  setIsMessageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPeopleTaskModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export type supportingTaskType = {
  id: number;
  supporter_id: number;
  // TODO apiでcreate_userをuser_nameに買える
  created_user: string;
  user_id: number;
  title: string;
  description: string | null;
  date: string;
  hour: string;
  minute: string;
  status: number;
  created_at: string;
  updated_at: string;
  start_time: number;
  end_time: number;
};
export const PeopleTaskCard = (props: PeopleTaskProps) => {
  const handleSetMessages = (taskId: number) => {
    const filteredArray = props.messagesList.filter(
      (obj: any) => obj.task_id === taskId
    );
    props.setTaskMessageList!(filteredArray);

    props.setIsMessageModalOpen(true);
    props.setSelectedTaskId(taskId);
  };

  const handlePeopleTaskModalOpen = (taskId: number) => {
    props.setSelectedTaskId(taskId);
    props.setIsPeopleTaskModalOpen(true);
  };
  const convertToCurrentTime = (time: any) => {
    if (!time) return;
    const timeStamp = Number(time);
    const localTimeStamp = new Date(timeStamp);
    const hours = localTimeStamp.getHours();
    const minutes = localTimeStamp.getMinutes();

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return formattedTime;
  };

  const taskExpired = (time: number) => {
    const currentTime = new Date().getTime();
    if (time < currentTime) {
      return styles.expiredTaskWrapper;
    }
    return styles.taskCardWrapper;
  };
  return (
    <>
      {props.supportingTaskList.length > 0 ? (
        <div>
          <p className={styles.taskCount}>
            {props.taskStatus === "ongoing"
              ? "Supporting task count："
              : "Finished supporting task count："}
            {props.supportingTaskList.length}
          </p>
          <div className={styles.TaskListWrapper}>
            {props.supportingTaskList.map((list) => {
              return (
                <div className={taskExpired(list.end_time)} key={list.id}>
                  <div className={styles.taskCardTopWrapper}>
                    <motion.div
                      className={styles.taskCardTop}
                      onClick={() => handlePeopleTaskModalOpen(list.id)}
                    >
                      <div className={styles.taskCardTitleWrapper}>
                        <p className={styles.taskCardTitle}>{list.title}</p>
                      </div>
                      <div className={styles.taskCardInfo}>
                        <div className={styles.taskTime}>
                          {list.date}　{convertToCurrentTime(list.start_time)}{" "}
                          〜{convertToCurrentTime(list.end_time)}
                        </div>
                        {list.supporter_id ? (
                          <div className={styles.supporterName}>
                            You're supporting：{list.created_user}
                          </div>
                        ) : (
                          <div className={styles.supporterName}>
                            not assigned yet
                          </div>
                        )}
                      </div>
                    </motion.div>
                  </div>
                  <motion.div className={styles.taskCardBottom}>
                    <motion.div
                      layoutId={`${list.id}`}
                      onClick={() => handleSetMessages(list.id)}
                    >
                      <MessageContainer
                        userInfo={props.userInfo}
                        supporterName={list.created_user}
                        senderId={props.userInfo.userId!}
                        receiverId={list.user_id}
                        taskId={list.id}
                        messagesList={props.messagesList}
                        setMessagesList={props.setMessagesList}
                      />
                    </motion.div>
                  </motion.div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        // TODO change it to no task
        <div>
          <p className={styles.taskCount}>
            {props.taskStatus === "ongoing"
              ? "Supporting task count："
              : "Finished supporting task count："}
            {props.supportingTaskList.length}
          </p>
          <div className={styles.withoutTask}>No task yet</div>
        </div>
      )}
    </>
  );
};
